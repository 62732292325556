<template>
  <Modal @close-modal="closeModal()" :title="title">
      <template #content>
        <pedido-detalle :pedido="pedido"></pedido-detalle>
        <div v-if="loading" class="d-flex justify-content-center">
            <img class="loading-icon" src="@/assets/loading.gif" alt="" />
        </div>
      </template>
  </Modal>
</template>

<script>
 import PedidoDetalle from '../PedidoDetalle';
 import Modal from '../../common/modal';
import httpClient from '@/api/httpClient';
 export default {
    name: "modal-pedido",
    components: {
      PedidoDetalle, Modal
    },
    props:{
      id_pedido: Number,
      modo: String,
      ocultar_lineas: Boolean,
      cooperativa_servicio: String,
      termino: String
    },
    data () {
        return {
          loading:false,
          pedido: null
        };
    },
    created(){
      this.loading=true,
      httpClient.get(`/pedidos/${this.id_pedido}?modo=${this.modo}&ocultar_lineas=${+this.ocultar_lineas}&cooperativa_servicio=${this.cooperativa_servicio}&termino=${this.termino}`).then(response => {
        this.pedido=response.data.pedido;
        this.loading=false;
      });
    },
    methods:{
      closeModal(){
        this.$emit("cerrar-modal")
      }
    },
    computed:{
      title(){
        return this.pedido ? `Pedido ref. ${this.pedido.num_pedido_origen}` : ''
      }
    }

  }
</script>
