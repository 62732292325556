<template>
  <div id="search-view">
    <div class="search-view-fixed">
      <div class="search-view-header">
          <div class="search-view-container">
            <div class="row   search-view-container_header align-items-center">
             <div class="col-12 col-md-8 ">
                  <h2>{{ $t("dashboard.historico-pedidos.title") }} </h2>
              </div>
             <div class="col-12 col-md-4   pb-5 pb-md-0  text-right">
                <b-button @click="MOSTRAR_MODAL_CESTA(true)" icon="icon-shopping-cart-outline" iconPosition="left" style="background-color:#00478b">Mi cesta
                <span style="
                margin-left: 10px;
                color: #005BBB;
                height: 19px;
                width: 19px;
                padding: 3px;
                background-color: #ffff;
                border-radius: 50%;
                display: inline-block;
                ">{{ cesta.items.length }}</span></b-button>
              </div>
            </div>
          </div>
      </div>
      <div class="search-view-container mb-3" style="position: relative;top:-45px">
        <div class="mb-3">
            <div class="row">
                <div class="col-md-3 text-left">
                  <date-picker v-model="range" is-required :model-config="{type: 'string', mask: 'YYYY-MM-DD'}" is-range>
                    <template v-slot="{ inputValue, inputEvents }">
                    <div class="row">
                      <div class="row">
                        <div class="col-md-6">
                          Fecha Inicio<br/>
                          <input
                            class="form-input"
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            style="width: 90%"
                          />
                        </div>
                        <div class="col-md-6"> 
                          Fecha Fin<br/>
                          <input
                            class="form-input"
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            style="width: 90%"
                          />
                        </div>
                      </div>
                    </div>
                    </template>
                  </date-picker>
                </div>
                <div class="col-md-2 text-left">
                  CN, Ean, Nombre<br/>
                  <input  type="text" class="form-input" v-model="filtros.termino" placeholder="" style="width:80%">
                </div> 
                <div class="col-md-2 text-left">
                  Cooperativa sirve
                  <select class="form-input" v-model="filtros.cooperativa_servicio" style="background-color: #ffffff; width:80%">
                      <option value="">Todas</option>
                      <option :value="mi_cooperativa">Mi cooperativa</option>
                      <option value="-1">Otras cooperativas</option>
                      <option v-for="item in otras_cooperativas" :value="item.value">{{ item.text }}</option>
                   </select>
                </div>
                <div class="col-md-2 text-left">
                  Origen
                  <select class="form-input" v-model="filtros.origen" style="background-color: #ffffff; width:80%">
                      <option value="">Todos</option>
                      <option v-for="item in origenes" :value="item.value">{{ item.text }}</option>
                   </select>
                  </div>
                <div class="col-md-2 p-4"><input type="checkbox" class="bg-white border ml-3 px-2 py-1 rounded" value="1" v-model="filtros.ocultar_lineas">  Sólo líneas servidas</div>
                <div class="col-md-1 p-4"><button class="btn btn boton btn-primary" @click="realizarBusqueda()">Buscar</button></div>
            </div>
        </div>
          <div v-if="loading" class="d-flex justify-content-center">
            <img class="loading-icon" src="@/assets/loading.gif" alt="" />
          </div>
          <Table v-else="loading"  :cabeceras="cabeceras" :items="pedidos" sort-by="fecha">
             <template slot="cell(num_articulos)" slot-scope="prop">
              {{ prop.item.num_lineas_consulta }} / {{ prop.item.num_lineas_total }}
             </template>
             <template slot="cell(fecha)" slot-scope="prop">
                {{ prop.item.fecha | format_fecha }}
             </template>
             <template slot="cell(edit)" slot-scope="prop">
                <span class="icon-eye-outline" @click="mostrarModalPedido(prop.item)" style="cursor: pointer;font-size:18px;color:#005BBB"/>
             </template>
          </Table>
      </div>
    </div>
    <div>

    </div>
    <div class="search-view-results">
   
    </div>
    <modal-cesta v-if="modal_cesta.value" @cerrar-modal="MOSTRAR_MODAL_CESTA(false)"/>
    <modal-pedido v-if="modal_pedido.mostrar" :id_pedido="modal_pedido.pedido_seleccionado.id" :modo="modal_pedido.pedido_seleccionado.modo" :ocultar_lineas="filtros.ocultar_lineas" :cooperativa_servicio="filtros.cooperativa_servicio" :termino="filtros.termino" @cerrar-modal="cerrarModalPedido()"/>
  </div>
</template>

  <script>
    import pedidos from "@/api/pedidos";
    import { mapState,mapMutations, mapActions } from "vuex";
    import ModalCesta from "@/components/integrado/modal/ModalCesta.vue";
    import ModalPedido from "@/components/integrado/modal/ModalPedido.vue";
    import httpClient from "@/api/httpClient";
    import Table from "@/components/common/table";
    import {DatePicker,IconArrowRight} from 'v-calendar';
    import moment from "moment";

    export default {
      name: "historico-pedidos",
      extends: pedidos,
      components: {
        ModalCesta,ModalPedido, Table, DatePicker, IconArrowRight
      },
      created() {
         this.loading=true;
         httpClient.get("/pedidos").then(
          response => {
            this.pedidos=response.data.pedidos;
            this.loading=false;
          }
        );
      },
      data() {
        return {
          range: {
            start: moment().subtract(3,'d').format('YYYY-MM-DD'),
            end: moment().format('YYYY-MM-DD')
          },
          filtros:{
            fecha_inicio: '',
            fecha_fin: '',
            termino: '',
            cooperativa_servicio: '',
            ocultar_lineas: true,
            origen:''
          },
          cooperativas_servicio:[
              {text:'Cofano',value:55},
              {text:'Cofarle',value:80},
              {text:'Cofas',value:20},
              {text:'Novaltia',value:40},
              {text:'DFG',value:35},
              {text:'Nafarco',value:10},
              {text:'Vadefarma',value:94},
          ],
          origenes:[
              {text:'Pedido integrado (Web)',value:'int_v2_web'},
              {text:'Pedido integrado (Fedicom)',value: 'int_v2_fed'},
              {text:'Transfer',value: 'transfer'},
              {text:'Directonline',value: 'directonline'},
              {text:'Plataforma',value: 'plataforma'},
              {text:'Ortopedia Cinfa',value: 'ortopedia_cinfa'}
          ],
          items: [
            { urlName: "/", text: "Integrado" },
            { urlName: "/historico-pedidos", text: "Histórico pedidos" },
          ],
          cabeceras:[
              {
                  key:"pedido_origen",
                  label:"ID",
                  width:"10px",
                  sortable:"true"
              },
              {
                  key:"fecha",
                  label:"Fecha",
                  width:"300px",
                  sortable:"true"
              },
              {
                  key:"origen",
                  label:"Origen",
                  width:"100px"
              },
              {
                  key:"num_articulos",
                  label:"Num. líneas",
                  width:"100px"
              },
              {
                  key:"edit",
                  label:"",
                  width:"100px"
              }
           ],
          pedidos:[],
          modal_pedido:{
            pedido_seleccionado:{},
            mostrar:false
          },
          loading:false
        };
      },
      computed:{
        ...mapState(['user']),
        ...mapState('cesta',['cesta','modal_cesta']),
        mi_cooperativa(){
            return parseInt(this.user.cooperativa.id_unnefar);
        },
        otras_cooperativas(){
          return this.cooperativas_servicio.filter(item => item.value != this.user.cooperativa.id_unnefar);
        }
      },
      methods: {
        ...mapActions('cesta',['MOSTRAR_MODAL_CESTA']),
        mostrarModalPedido(pedido){
            this.modal_pedido.pedido_seleccionado=pedido;
            this.modal_pedido.mostrar=true;
        },
        cerrarModalPedido(){
          this.modal_pedido.pedido_seleccionado={};
          this.modal_pedido.mostrar=false;
        },
        realizarBusqueda(){
          this.loading=true;
          this.filtros.fecha_inicio=this.range.start;
          this.filtros.fecha_fin=this.range.end;
          httpClient.post("/pedidos",this.filtros).then(
          response => {
            this.pedidos=response.data.pedidos;
            this.loading=false;
          }
        );
        }
      },
    };
  </script>

 <style lang="scss" scoped>

  #search-view {
    .search-view-fixed {
      position:relative;
      width: 100%;
      z-index: 6000;
      background-color: #f7f8fc;
    }
    .search-view-header {
      background-color: #fff;
    }

    .search-view-filters {
      margin-top: -30px;
    }
    .search-view-container {
      max-width: 1300px;
      margin: 0 auto;
      padding: 0 30px;

    }
    .search-view-container_header {

      min-height: 180px;
    }


    .search-view-paginator {
      width:100%;
      text-align: center;
      border:1px #000000
    }

    .search-background {
      position: relative;
      .search-select-input {
        width: 100%;
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        box-shadow: 0px 1px 4px rgba(188, 186, 186, 0.25);
        .input-custom,
        .select-custom {
          box-shadow: none;
          background: none;
        }
        .input-custom {
          position: relative;
          width: calc(100%);
          flex-basis: calc(100%);
        }
      }
    }

    #search-view-btn-load-more {
      text-align: center;
      margin: 0 auto;
    }

    .form-input{
        height: 50px;
        border-radius: 5px;
        padding-left: 10px;
        border: 1px solid #e6eff9;
        outline: none;
        background-color: #ffffff;
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
  }
}

</style>
