<template>
    <span ref="container" class="tooltip-style" :class="background">
      <span class="target" ref="target">
        <slot/>
      </span>
      <div ref="content" class="content" role="tooltip" :class="{'is-open': isOpen}">
        <slot name="content"></slot>
        <div class="arrow" data-popper-arrow></div>
      </div>
    </span>
  </template>
  <script>
  import uuid from 'frontendcommon/src/mixins/uuid'
  import { createPopper } from '@popperjs/core'
  export default {
    name: 'tooltip-ext',
    mixins: [uuid],
    props: {
      position: {
        type: String,
        default: 'auto'
      },
      showEvent: {
        type: String,
        default: 'click' // hover
      },
      background: {
        type: String,
        default: 'black' // 'white'
      },
      enabled: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        isOpen: false,
        popperInstance: null
      }
    },
    methods: {
      open () {
        if (this.enabled) {
          this.isOpen = true
          this.popperInstance.update()
        }
      },
  
      close () {
        this.isOpen = false
        this.popperInstance.update()
      },
  
      globalClose (event) {
        if (this.isOpen && event && !this.$refs.container.contains(event.target)) {
          this.close()
        }
      },
      toggle () {
        if (this.isOpen) {
          this.close()
        } else {
          this.open()
        }
      },
  
      setShowEvent () {
        if (this.showEvent === 'click') {
          this.$refs.target.addEventListener('click', this.toggle)
          if (window) {
            window.document.addEventListener('click', this.globalClose)
          }
        }
        if (this.showEvent === 'hover') {
          this.$refs.target.addEventListener('mouseenter', this.open)
          this.$refs.target.addEventListener('mouseleave', this.close)
        }
      },
      setVisibilityObserver () {
        if (window.IntersectionObserver) {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
              if (this.isOpen && !entry.isIntersecting) {
                this.close()
              }
            })
          }, { threshold: 1 })
          observer.observe(this.$refs.target)
        }
      },
      init () {
        this.popperInstance = createPopper(this.$refs.target, this.$refs.content, {
          placement: this.position,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8]
              }
            }
          ]
        })
        this.setShowEvent()
        this.setVisibilityObserver()
      }
    },
    mounted () {
      this.init()
    }
  }
  </script>
  <style lang="scss" scoped>
  @import 'frontendcommon/src/scss/config';
  .target{
    display: inline-block;
  }
  .content {
    border: 1px dotted #a4a4a4;
    display: inline-block;
    padding: 12px 18px;
    border-radius: 6px;
    display: none;
    z-index: 2000;
    text-align: left;
  }
  .is-open{
    display: block;
  }
  .arrow,
  .arrow::before {
    position: absolute;
    width: 10px;
    height: 10px;
    background: inherit;
  }
  
  .arrow {
    visibility: hidden;
  }
  

  
  .content[data-popper-placement^='top'] > .arrow {
    bottom: -5px;
  }
  
  .content[data-popper-placement^='bottom'] > .arrow {
    top: -5px;
  }
  
  .content[data-popper-placement^='left'] > .arrow {
    right: -5px;
  }
  
  .content[data-popper-placement^='right'] > .arrow {
    left: -5px;
  }
  .tooltip-style.black{
    .content{
      background: $brand-03;
      color: white;
    }
  }
  .tooltip-style.white{
    .content{
      background: white;
      color: $brand-03;
    }
  }
  </style>
  