import { render, staticRenderFns } from "./SelectCustom.vue?vue&type=template&id=6fc8235d&scoped=true"
import script from "./SelectCustom.vue?vue&type=script&lang=js"
export * from "./SelectCustom.vue?vue&type=script&lang=js"
import style0 from "./SelectCustom.vue?vue&type=style&index=0&id=6fc8235d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc8235d",
  null
  
)

export default component.exports