<template>
  <div class="list-group-item p-0">
    <div class="row pr-3 pl-3 mb-1 align-items-center">
      <div class="col-12 text-center" style="height: 80px; background-color: #ffffff">
        <img v-if="item" class="logo" :src="getLogoCooperativa" :alt="item.nombre" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DraggableItem",
  props: {
    item: {
      type: Object,
      required: false
    }
  },
  computed:{
    getLogoCooperativa() {
      if(this.item != 'undefined'){
          if(this.item.nombre.toLowerCase() == "vadefarma"){
            return require(`../../../assets/cooperativas/color/vadefarma.png`);
          }
          return require(`../../../assets/cooperativas/color/${this.item.nombre.toLowerCase()}.svg`);
      }   
    }
  }
}
</script>

<style scoped>

</style>
