<template>
  <div id="search-view">
    <div class="search-view-fixed">
      <div class="search-view-header"> 
          <div class="search-view-container">
            <div class="row search-view-container_header align-items-center">
             <div class="col-12 col-md-8 ">
              <h2>{{ $t("dashboard.prioridades.title") }}</h2>
           </div>
             <div class="col-12 col-md-4   pb-5 pb-md-0  text-right">
              <b-button @click="MOSTRAR_MODAL_CESTA(true)" icon="icon-shopping-cart-outline" iconPosition="left" style="background-color:#00478b">Mi cesta 
                <span style="
                margin-left: 10px;
                color: #005BBB;
                height: 19px;
                width: 19px;
                padding: 3px;
                background-color: #ffff;
                border-radius: 50%;
                display: inline-block;
                ">{{ cesta.items.length }}</span></b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div> 
      <div class="container my-3">
        <div v-if="loading" class="d-flex justify-content-center">
          <img class="loading-icon" src="@/assets/loading.gif" alt="" />
        </div>
        <div v-else="loading">
          <template>
            <div class="row">
              <div class="col-6">
                <h3>Excluidas</h3>
                <draggable
                  class="list-group border"
                  :style="style"
                  group="prioridades"
                  :list="excluidas"
                  @start="drag = true"
                  @end="drag = false"
                  v-bind="drag_options"
                  :sortable="false"
                >
                  <transition-group
                    type="transition"
                    :style="style"
                    :name="!drag ? 'flip-list' : null"
                  >
                    <draggable-item
                      v-for="item in excluidas"
                      :key="item.cod_cooperativa_preparadora"
                      :item="item.cooperativa"
                    />
                  </transition-group>
                </draggable>
              </div>
              <div class="col-6">
                <h3>Seleccionadas</h3>
                <draggable
                  class="list-group border"
                  :style="style"
                  group="prioridades"
                  :list="seleccionadas"
                  @start="drag = true"
                  @end="drag = false"
                  @change="change"
                  v-bind="drag_options"
                >
                  <transition-group
                    type="transition"
                    :style="style"
                    :name="!drag ? 'flip-list' : null"
                  >
                    <draggable-item
                      v-for="item in seleccionadas"
                      :key="item.cod_cooperativa_preparadora"
                      :item="item.cooperativa"
                      :prioridad="item.prioridad"
                    />
                  </transition-group>
                </draggable>
              </div>
            </div>
            <div v-if="cambios && !sending" class="text-center mt-2">
              <b-button @click="setPrioridades" class="btn btn-primary mr-2"
                >Guardar prioridades</b-button
              >
              <b-button @click="getPrioridades" variant="btn btn-outline-primary"
                >Descartar cambios</b-button
              >
            </div>
            <div v-else-if="sending" class="d-flex justify-content-center mt-2">
              <img class="loading-icon" src="@/assets/loading.gif" alt="" />
            </div>
          </template>
        </div>

        <!--data-table v-else="loading" :fields="fields" :items="prioridades">
          <template slot="cell(prioridad)" slot-scope="prop">
            {{ prop.item.prioridad }}
          </template>
          <template slot="cell(cod_cooperativa_preparadora)" slot-scope="prop">
            {{ prop.item.cooperativa.nombre }}
          </template>
          <template slot="cell(excluir)" slot-scope="prop">
            {{ prop.item.excluir? 'Sí' : 'No' }}
          </template>
        </data-table-->
      </div> 
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ModalCesta from "@/components/integrado/modal/ModalCesta.vue";
import httpClient from "@/api/httpClient";
import Draggable from "vuedraggable";
import DraggableItem from "../../components/integrado/draggable/DraggableItem";

export default {
  name: "prioridades",
  components: {
    ModalCesta,
    Draggable,
    DraggableItem,
  },
  created() {
    this.getPrioridades();
  },
  data: () => ({
    items: [
      { urlName: "/", text: "Integrado" },
      { urlName: "/historico-pedidos", text: "Histórico pedidos" },
      { urlName: "/prioridades", text: "Prioridades" },
    ],
    fields: [
      {
        key: "prioridad",
        label: "Prioridad",
        width: "10px",
      },
      {
        key: "cod_cooperativa_preparadora",
        label: "Cooperativa",
        width: "30px",
      },
      {
        key: "excluir",
        label: "Excluída",
        width: "10px",
      },
    ],
    prioridades: [],
    loading: false,
    drag: false,
    cambios: false,
    sending: false,
    prioridad_modif: [],
  }),
  computed: {
    ...mapState("cesta", ["cesta", "modal_cesta"]),
    drag_options() {
      return { animation: 0, ghostClass: "ghost" };
    },
    seleccionadas() {
      return this.prioridades.filter((item) => item.excluir === 0);
    },
    excluidas() {
      return this.prioridades.filter((item) => item.excluir === 1);
    },
    altura() {
      return this.prioridades.length * 90 || 5 * 65;
    },
    style() {
      return `display:block; height: ${this.altura}px`;
    },
  },
  methods: {
    ...mapActions("cesta", ["MOSTRAR_MODAL_CESTA"]),
    getPrioridades() {
      this.loading = true;
      this.cambios = false;
      httpClient.get("/datos-prioridades").then((response) => {
        this.prioridades = response.data.prioridades;
        //console.log("created", this.prioridades);
        this.loading = false;
      });
    },
    setPrioridades() {
      console.log("setPrioridades", this.prioridades);
      for (let a in this.prioridades) {
        delete this.prioridades[a].cooperativa;
      }
      this.sending = true;
      httpClient
        .post("/set-prioridades", {
          prioridades: this.prioridades,
        })
        .then((response) => {
          this.sending = false;
          //console.log("Cambios realizados");
          this.getPrioridades();
        });
    },
    change(e) {
      this.cambios = true;
     
      if (e.moved) {
          console.log("Cooperativa moved", e.moved.element.cod_cooperativa_preparadora)
          if(e.moved.element.cod_cooperativa_preparadora != "94"){
            this.prioridad_modif = this.prioridades.filter(
              (item) =>
                item.cod_cooperativa_preparadora ===
                e.moved.element.cod_cooperativa_preparadora
            );

            if (e.moved.newIndex < e.moved.oldIndex) {
              let indice = e.moved.newIndex + 1;
              let antiguo = e.moved.oldIndex + 1;
              this.prioridades_modif = this.prioridades.filter(
                (item) => item.prioridad >= indice && item.prioridad <= antiguo
              );
              for (let a in this.prioridades_modif) {
                this.prioridades_modif[a].prioridad += 1;
              }
              this.prioridad_modif[0].prioridad = e.moved.newIndex + 1;
            } else {
              let indice = e.moved.newIndex + 1;
              let antiguo = e.moved.oldIndex + 1;
              this.prioridades_modif = this.prioridades.filter(
                (item) => item.prioridad <= indice && item.prioridad > antiguo
              );
              for (let a in this.prioridades_modif) {
                this.prioridades_modif[a].prioridad -= 1;
              }
              this.prioridad_modif[0].prioridad = e.moved.newIndex + 1;
            }
        }
      } else if (e.added) {
        console.log("Cooperativa added", e.added.element.cod_cooperativa_preparadora)
        this.prioridad_modif = this.prioridades.filter(
          (item) =>
            item.cod_cooperativa_preparadora ===
            e.added.element.cod_cooperativa_preparadora
        );
        this.prioridad_modif[0].excluir = 0;
        let indice = e.added.newIndex + 1;
        for (let b in this.prioridades) {
          if (this.prioridades[b].prioridad >= indice) {
            this.prioridades[b].prioridad += 1;
          }
        }
        //this.prioridad_modif[0].prioridad = e.added.newIndex+1;
        this.prioridad_modif[0].prioridad = e.added.newIndex + 1;
      } else if (e.removed) {
        console.log("Cooperativa removed", e.removed.element.cod_cooperativa_preparadora)
        if(e.removed.element.cod_cooperativa_preparadora != "94"){
          this.prioridad_modif = this.prioridades.filter(
            (item) =>
              item.cod_cooperativa_preparadora ===
              e.removed.element.cod_cooperativa_preparadora
          );
          this.prioridad_modif[0].excluir = 1;
          let indice = e.removed.oldIndex + 1;
          for (let a in this.prioridades) {
            if (this.prioridades[a].prioridad > indice) {
              this.prioridades[a].prioridad -= 1;
            }
          }
          this.prioridad_modif[0].prioridad = 0;

        }
      }

      this.prioridades.sort(function (a, b) {
        return a.prioridad - b.prioridad;
      });

      //console.log("CAMBIO", this.prioridades);
    },
  },
};
</script>
 <style lang="scss" scoped>
  #search-view {
    .search-view-fixed {
      position:relative;
      width: 100%;  
      z-index: 6000;
      background-color: #f7f8fc;
    }
    .search-view-header {
      background-color: #fff;
    }
    .search-view-results {
     // margin-top: 210px;
    }

    .search-view-filters {
      margin-top: -30px;
    }
    .search-view-container {
      max-width: 1300px;
      margin: 0 auto;
      padding: 0 30px;
      
    }
    .search-view-container_header {
       
      min-height: 180px;
    }
    

    .search-view-paginator {
      width:100%;
      text-align: center;
      border:1px #000000
    }

    .search-background {
      position: relative;
      .search-select-input {
        width: 100%;
        display: flex;
        align-items: center;
        background: #fff;
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        box-shadow: 0px 1px 4px rgba(188, 186, 186, 0.25);
        .input-custom,
        .select-custom {
          box-shadow: none;
          background: none;
        }
        .input-custom {
          position: relative;
          width: calc(100%);
          flex-basis: calc(100%);
        }
      }
    }

    #search-view-btn-load-more {
      text-align: center;
      margin: 0 auto;
    }
  }
</style>