<template>
    <div v-if="pedido">
        <div class="mb-3" v-if="tipo == 'individual'"><h5>Pedido {{ parseInt(pedido.num_pedido_origen) }}</h5></div>
        <div>
            <span>Origen</span> | <span class="articulo_codigo_valor">{{ pedido.origen }}</span>
        </div>
        <div>
            <span>Fecha</span> | <span class="articulo_codigo_valor">{{ pedido.fecha | format_fecha }}</span>
        </div>
        <div v-if="lineas_sin_servir" style="text-align: right;">
            {{ pedido.lineas.length}}/{{ pedido.lineas_count }} líneas
        </div>
        <div class="mt-3 mb-3">
          <Table :cabeceras="cabeceras_visibles" :items="pedido.lineas">
              <template slot="cell(imagen2d)" slot-scope="prop">
                  <img v-if="prop.item.producto"  :src="prop.item.producto.imagen" class="img">
                  <img v-else-if="prop.item.producto2"  :src="prop.item.producto2.imagen" class="img">
                  <img v-else  src="@/assets/no-foto.png" class="img">
              </template>
              <template slot="cell(descripcion)" slot-scope="prop" >
                  <span class="articulo_nombre">{{ !pedido.tipo || pedido.tipo == 'Int V2'? (prop.item.producto && prop.item.producto.descripcion ? prop.item.producto.descripcion : (prop.item.producto2 && prop.item.producto2.descripcion ? prop.item.producto2.descripcion  : 'ARTICULO DESCONOCIDO')   ) : (prop.item.producto && prop.item.producto.descripcion ? prop.item.producto.descripcion : 'ARTICULO DESCONOCIDO') }}</span><br>
                  <span class="articulo_codigo">Código</span> <span class="articulo_codigo_valor">{{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.codigo_producto : prop.item.producto.codigo_nacional }}</span>
              </template>
              <template slot="cell(precio)" slot-scope="prop" >
                  {{ prop.item.producto && !prop.item.precio ? $n(prop.item.producto.pvu) :  $n(prop.item.precio)  }} &euro; 
              </template>
              <template slot="cell(cantidad_pedida)" slot-scope="prop">
                  <span class="cantidad_pedida">{{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.cantidad_pedida : prop.item.cantidad}}</span>
              </template>
              <template slot="cell(cantidad_servida)" slot-scope="prop">
                  <span class="cantidad_servida">{{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.cantidad_servida : prop.item.respuestas? prop.item.respuestas[prop.item.respuestas.length-1].cantidad_servida : '' }}</span>
              </template>
              <template slot="cell(plazo)" slot-scope="prop">
                  <span class="articulo_plazo">{{ !pedido.tipo || pedido.tipo == 'Int V2'? prop.item.texto_respuesta : '' }}</span>
              </template>
              <template slot="cell(codigo_incidencia)" slot-scope="prop">
                  <span class="articulo_incidencia">{{ !pedido.tipo || pedido.tipo == 'Int V2'? incidenciaDescripcion(prop.item) : prop.item.respuestas? prop.item.respuestas[prop.item.respuestas.length-1].respuesta : '' }}</span>
              </template>
          </Table>
        </div>
    </div>
  </template>

  <script>
  import httpClient from "@/api/httpClient";
  import Table from "@/components/common/table";
  import {mapGetters, mapState} from "vuex";
  import axios from "axios";

    export default {
      name: "pedido-detalle",
      mixins: [],
      props: {
        pedido: Object,
        tipo:{
            type:String,
            default:""
        }
      },
      components: {
        Table
      },
      data: () => {
         return{
             cabeceras:[
                  {
                      key:"imagen2d",
                      label:"Imagen",
                      class:"w-10"
                  },
                  {
                      key:"descripcion",
                      label:"Descripción",
                      class:"w-25"
                  },
                  {
                      key:"precio",
                      class:"w-10",
                      label:"Precio"
                  },
                  {
                      key:"cantidad_pedida",
                      class:"w-5",
                      label:"Cant. Pedida"
                  },
                  {
                      key:"cantidad_servida",
                      class:"w-5",
                      label:"Cant. Servida"
                  },
                  {
                      key:"plazo",
                      class:"w-10",
                      label:"Plazo"
                  },
                  {
                      key:"codigo_incidencia",
                      class:"w-10",
                      label:"Incidencia"
                  }
             ]
         }
      },
    computed:{
        ...mapState(["user"]),
        themeColors(){
            return this.$root.$el.attributes["style"].value;
        },
        plazoPedido(){
            return this.plazo(this.pedido.plazo);
        },
        cabeceras_visibles(){
            console.log("user",this.user);
            if(this.user && this.user.cooperativa.precio && this.user.cooperativa.precio.toLowerCase() == 'sin precio'){
                return this.cabeceras.filter(item => item.key != "pvu" && item.key != "precio");
            }else {
                return this.cabeceras;
            }
        },
        lineas_sin_servir(){
            return this.pedido.lineas_count - this.pedido.lineas.filter(item => item.cantidad_servida > 0).length
        }
      },
      created() {

      },
    methods:{
        plazo(p){
            switch(p){
                case 'A':
                    return "SIGUIENTE REPARTO";
                case 'B':
                    return "ENTREGAMOS EN 24 HORAS";
                case 'C':
                    return "ENTREGRAMOS EN 48 HORAS";
                case 'D':
                    return "ENTREGAMOS EN M��S 48 HORAS";
            }
        },
        incidenciaDescripcion(item){

            if(item.incidencia){
                let codigo=`00${item.incidencia.codigo}`.slice(-2);
                return `${codigo} - ${item.incidencia.descripcion}`
            }else{
                return '';
            }

        },
        /*mostrarPrecio(item){
            if(item.producto && item.producto.pvu){
                return parseFloat(item.producto.pvu).toFixed(2).replace(".",",");
            }else{
                return parseFloat(item.producto.pvl).toFixed(2).replace(".",",");
            }
        },
        codigo_nacional(item){
            let valor=item.producto?.codigo_nacional || item.codigo_producto;
            return parseInt(valor);
        }*/
      mostrarImagen(cod_producto){
        return `https://repofarma.com/gallery/2d.php?cn=${cod_producto}`;
      }
    }
  };
  </script>


